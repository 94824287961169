.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  background-color: white;
  width: 100%;
  height: 100%;
  text-align: "center";
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 40px;
  /* margin-bottom: -2; */
}

.App-header2 {
  padding-top: 20px;
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: "center";
  /* flex-direction: row; */
  font-size: calc(10px + 2vmin);
  color: black;
  text-align: "center";
  margin-right: 150px;
  margin-left: 150px;
}

.App-header3 {
  position: relative;
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: "center";
  /* flex-direction: row; */
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: black;
  text-align: "center";
}

.App-link {
  color: #61dafb;
}

.Top-hashtag:before,
.Top-hashtag:after
 {
  background-color: transparent;
  border-color: ivory;
  top: 5%;
  left: 35%;
  position: absolute;
  border: solid;
  padding: 2%;
  width: 400px;
  transition: opacity 0.5s linear;
}

.Top-hashtag:after {
  content: "#いぬいどんどんすきになる";
  opacity: 0;
}

.Top-hashtag:before {
  content: "戌亥とこ非公式ファンサイト";
  opacity: 1;
}
.Top-hashtag:hover:before {
  opacity: 0;
}
.Top-hashtag:hover:after {
  opacity: 1;
}

.hogehoge
 {
  background-color: transparent;
  border-color: ivory;
  top: 2%;
  left: 80%;
  /* margin-left: 100px; */
  color: ivory;
  font-size: 20px;
  content: "aaa";
  position: absolute;
  border: solid;
  padding: 2%;
  width: 25px;
  /* height: 1000px; */
  /* transition: opacity 0.5s linear; */
}

.hogehoge2
 {
  background-color: transparent;
  border-color: ivory;
  top: 2%;
  left: 60%;
  color: ivory;
  font-size: 20px;
  position: absolute;
  border: solid;
  padding: 2%;
  width: 25px;
  /* height: 1000px; */
  /* transition: opacity 0.5s linear; */
}

.hogehoge3
 {
  background-color: transparent;
  border-color: ivory;
  top: 2%;
  left: 40%;
  /* margin-left: 150px; */
  color: ivory;
  font-size: 20px;
  position: absolute;
  border: solid;
  padding: 2%;
  width: 25px;
  /* height: 1000px; */
  /* transition: opacity 0.5s linear; */
}

.hogehoge4
 {
  background-color: transparent;
  border-color: ivory;
  top: 2%;
  left: 15%;
  color: ivory;
  font-size: 50px;
  position: absolute;
  border: solid;
  padding: 2%;
  width: 50px;
  /* height: 1000px; */
  /* transition: opacity 0.5s linear; */
} 

.caroucel {
  background-color: #9d3757;
}

.ahabtn {
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 50px;
  width: 100;
  height: 200;
  left: 65%;
  top: 30%;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  background: #9d3757;
  color: #fff;
  border-bottom: solid 4px #7e2c46;
  border-radius: 5px;
}

.ahabtn:active {
  /*ボタンを押したとき*/
  -webkit-transform: translateY(4px);
  transform: translateY(4px); 
  border-bottom: none; 
}
